import { Injectable } from '@angular/core';
import { RestRequest } from '../../services/rest-request';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(private restRequest: RestRequest, private http: HttpClient) {

  }
  getTemplate(query) {
    return this.restRequest.addAuthorization().get('users/templateList',query);
  }
  inspection(obj) {
    return this.restRequest.addAuthorization().post('users/inspectionform', obj);
  }
  updateinspection(obj) {
    return this.restRequest.addAuthorization().put('users/inspectionform', obj);
  }
  getinspectionlist(query) {
    return this.restRequest.addAuthorization().get('users/inspection_list',query);
  }
  getinspectionlistbyid(obj) {
    return this.restRequest.addAuthorization().get('users/inspection_list/' + obj);
  }
  selectinspectiondate(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiondate1', obj);
  }
  selectinspectiondate1(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiondate2', obj);
  }
  selectinspectiontime25(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiontime25', obj);
  }
  selectinspectiontime13(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiontime13', obj);
  }
  selectinspectiontime1(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiontime1', obj);
  }
  selectinspectiontime2(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectiontime2', obj);
  }

  restore(obj) {
    return this.restRequest.addAuthorization().put(`users/restore_inspection`,obj);
  }
  updateStatus(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectionstatus', obj);
  }
  updateEngineer(obj) {
    return this.restRequest.addAuthorization().put('users/updateEngineer', obj);
  }
  updatereason(obj) {
    return this.restRequest.addAuthorization().put('users/updateinspectionreason', obj);
  }
  getUserList_inspection() {
    return this.restRequest.addAuthorization().get('users/getUserList_inspection');
  }
  getinspectionlistbystatus(obj) {
    return this.restRequest.addAuthorization().get('users/inspection_list_by_status/' + obj);
  }

  getinspectionlistbystatusV2(obj,query) {
    return this.restRequest.addAuthorization().get('users/inspection_list_by_status/' + obj,query);
  }
  deleteinspectionlist(obj) {
    return this.restRequest.addAuthorization().delete('users/inspectionlistdel/' + obj);
  }
  sendeinspectionmail(obj) {
    return this.restRequest.addAuthorization().post('users/inspection_email', obj);
  }
  sendconfirmationemail(obj) {
    return this.restRequest.addAuthorization().post('users/inspection_confirmation_email', obj);
  }
}
