import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class QuotesStatusServices {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getQuotestList(status: any, page: any) {
    return this.restRequest
      .addAuthorization()
      .get(`/quotes?workFlowStatus=${status}&page=${page}`);
  }

  updateQuotesStatus(id: any, status: any) {
    return this.restRequest
      .addAuthorization()
      .put(`/quote/work-flow-status/${id}`, status);
  }

  // addOperatingExpense (data:any , id:any) {
  //   return this.restRequest
  //     .addAuthorization()
  //    .put(`sales-performance/target/${id}`, data);
  // }

  // getSalesDetail(id:any) {
  //   return this.restRequest
  //   .addAuthorization()
  //   .get(`sales-performance/${id}`)
  // }
}
