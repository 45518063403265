import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { QuotesStatusServices } from "./quotes-status.service";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";

@Component({
  selector: "app-quotes-status",
  templateUrl: "./quotes-status.component.html",
  styleUrls: ["./quotes-status.component.sass"],
})
export class QuotesStatusComponent implements OnInit {
  routeParams = {
    followed_up: {
      title: "To Be Followed-Up",
    },
    followed_completed: {
      title: "Follow-Up Complete",
    },
    call_back: {
      title: "Assesor to Call Back",
    },
    ready_yet: {
      title: "Client Not Ready Yet",
    },
    declined: {
      title: "Declined",
    },
    accepted: {
      title: "Accepted",
    },
  };

  modalRef: BsModalRef;
  status = "";
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  loader = false;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: QuotesStatusServices,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      this.title = this.routeParams[this.status].title;
      this.getList();
    });
  }

  getList() {
    if (!this.status) {
      return;
    }
    this.loader = true;
    this.api.getQuotestList(this.status, this.currentPageNo).subscribe(
      (result: any) => {
        const response = result.data;
        this.items = response.items;
        this.totalRecords = response.count;
        this.totalPages = response.totalPages;
        this.currentPageNo = response.currentPage;
        this.loader = false;
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
        this.loader = false;
      }
    );
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleUpdateStatus(id: any, event: any) {
    const data = {
      workFlowStatus: event.target.value,
    };
    this.loader = true
    this.api.updateQuotesStatus(id, data).subscribe(
      (data) => {
        this.getList();
      },
      (errro) => {
        this.commonService.toast(errro.error.message, "Error");
        this.loader = false;
      }
    );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "quote",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }
}
