import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserService } from './services/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './../../layouts/layouts.module';

@NgModule({
  imports: [
      CommonModule,
      RouterModule,
      LayoutsModule,
      ReactiveFormsModule
  ],
  providers: [
    JwtHelperService,
    UserService
  ],
  declarations: [ProfileComponent, ChangePasswordComponent]
})
export class UserModule { }
