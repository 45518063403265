import { Component, OnInit } from '@angular/core';
import { UserService } from './../../services/user.service';
import { ChangePasswordResponse } from './../../interfaces/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    token: String;
    submitted = false;
    changePasswordForm: FormGroup;
    loader:  Boolean;
    show_confirm_password: Boolean;
    show_password: Boolean;
    show_old_password: Boolean;
    constructor(
        private userService: UserService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
      this.token = '';
      this.show_confirm_password = false;
      this.show_password = false;
      this.show_old_password = false;
    }



    ngOnInit() {
      this.loader = false;
      this.changePasswordForm = this.formBuilder.group({
        old_password: ['', [Validators.required]],
        password: ['', [ Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/)]],
        confirm_password: ['', [ Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/)]]
      }, { validator: this.passwordValidator });
    }

    get f() {
      return this.changePasswordForm.controls;
    }

    passwordValidator(group) {
      const password = group.get('password');
      const confirm_password = group.get('confirm_password');

      if (password.value !== '' && confirm_password.value !== '' && password.value !== confirm_password.value) {

        return confirm_password.setErrors({notEquivalent: true});
      } else {

      }
    }

    changePassword() {
      this.submitted = true;
      this.loader = true;
      var userId = localStorage.getItem('userId');
      userId = JSON.parse(userId);
      if (!this.changePasswordForm.invalid) {
        var obj = {
          oldPassword: this.changePasswordForm.controls.old_password.value,
          newPassword: this.changePasswordForm.controls.password.value,
          cPassword: this.changePasswordForm.controls.password.value,
          _id:userId.toString()
      };
        this.userService.changePassword(obj).subscribe( (response: ChangePasswordResponse) => {
          this.loader = false;
          if (response.success === true ) {
            this.commonService.toast(`Password changed successfully.`, 'Success');
          } else {
              if(response.success === false) {
                  this.commonService.toast(response.message, 'Error');
              } else {
                  this.commonService.toast(response.message.toString(), 'Error');
              }
          }
        }, error => {
          this.loader = false; 
           this.commonService.toast(error.error.message, 'Error');
        });
      } else {
        this.loader = false;
      }
    }

    toggleOldPasswordView() {
        if(this.show_old_password === true) {
            this.show_old_password = false;
        } else {
            this.show_old_password = true;
        }
    }

    toggleConfirmView() {
        if(this.show_confirm_password === true) {
            this.show_confirm_password = false;
        } else {
            this.show_confirm_password = true;
        }
    }

    togglePasswordView() {
        if(this.show_password === true) {
            this.show_password = false;
        } else {
            this.show_password = true;
        }
    }

}
