import { Component, OnInit } from "@angular/core";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "../../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UserModalComponentComponent } from "./../user-modal-component/user-modal-component.component";
import { ExcelService } from "./../../../services/excel.service";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-inspection-list-pending",
  templateUrl: "./inspection-list-pending.component.html",
  styleUrls: ["./inspection-list-pending.component.sass"],
})
export class InspectionListPendingComponent implements OnInit {
  loader = true;
  items: any;
  items1: any;
  listData: any;
  status1: any;
  reason: any;
  email;
  email1;
  p = 1;
  nodata = false;
  searchText = "";
  selecttype;
  arr_temp = [];
  arr_temp_all = [];
  modalRef: BsModalRef;
  check: boolean;
  listtype_both: any;
  listtype_Accepted: any;
  listtype_Rejected: any;
  obj: any;
  excel = [];
  td;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;

  constructor(
    private inspection: InspectionService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private excelService: ExcelService
  ) {
    this.recordsPerPage = environment.defaultPageSize;
  }
  private searchTerms = new Subject<string>();
  ngOnInit() {
    this.getinspectionlist();
    this.status1 = [
      "Open",
      "Pending",
      "Qualified",
      "Accepted",
      "Rejected",
      "Confirmed",
    ];
    this.reason = ["Wrong service", "Wrong area"];
    this.arr_temp = [];
    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.pageChanged({ page: 1 });
        this.query.search = value;
        this.getinspectionlist();
      });
  }
  getValue(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
    // return (event.target as HTMLInputElement).value;
  }

  getinspectionlist() {
    this.query.search = this.searchText;
    this.inspection
      .getinspectionlistbystatusV2("Pending", this.query)
      .subscribe(
        (result: any) => {
          const items = result.data.data;
          this.items = items;
          this.items1 = items;
          this.totalRecords = result.data.totalCount;
          this.excel = [];
          //this.excel.push()
          let curVal = result.data;
          if (curVal.length > 0) {
            curVal.forEach((row) => {
              //delete row['_id'];
              //delete row['image'];
              console.log(row);
              this.excel.push(row);
            });
          }
          this.arr_temp_all = this.items.map((result) => result._id);
          // console.log(this.arr_temp_all);
          this.loader = false;
          this.nodata = false;
          this.listtype_both = true;
          this.listtype_Accepted = false;
          this.listtype_Rejected = false;
          this.nodata = false;
        },
        (error: any) => {
          this.loader = false;
          this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getinspectionlist();
  }

  changeStatus(_id, event) {
    const status = event.target.value;
    this.inspection.updateStatus({ _id, Status: status }).subscribe(
      (result: any) => {
        this.commonService.toast(`Status updated successfully.`, "Success");
        this.getinspectionlist();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  Deletefnc() {
    // this.obj = {
    //obj : this.arr_temp
    //}
    if (this.arr_temp.length == 0) {
      this.commonService.toast("Please select an enquiry to delete", "Error");
    } else {
      this.inspection.deleteinspectionlist(this.arr_temp).subscribe(
        (result: any) => {
          this.loader = true;
          this.getinspectionlist();
          this.loader = false;
          this.commonService.toast("Deletion successful", "Success");
        },
        (error: any) => {
          this.loader = false;
          //this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
    }
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excel, "User");
  }
  copydata(index) {
    var doc = "";
    var table = document.getElementById("myTable") as HTMLTableElement;
    for (var i = 0; i < 11; i++) {
      doc = doc + table.rows[index + 1].cells[i].innerText + "\t";
    }
    this.copyStringToClipboard(doc);
  }
  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    //el.style = {position: 'absolute', left: '-9999px'};

    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  pagecall() {
    //if (this.listtype_both == true) {
    this.getinspectionlist();
    //} else if (this.listtype_Accepted == true) {
    //this.getinspectionlistbystatus("Accepted");
    //} else if (this.listtype_Rejected == true) {
    //this.getinspectionlistbystatus("Rejected");
    //}
  }
  userModal(userid) {
    console.log(userid);
    const initialState = {
      userid: userid,
    };
    this.modalService.show(UserModalComponentComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getinspectionlist();
    });
  }
}
