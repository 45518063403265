import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HumanResourceService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  incidentReport(data: any) {
    return this.restRequest
      .addAuthorization()
      .get(`incident-reports?page=${data.page}&status=${data.status}`);
  }

  updateIncidentReport(id: any, data: any) {
    return this.restRequest
      .addAuthorization()
      .put(`/incident-report/${id}`, data);
  }

  updateAnnualLeave(id: any, data: any) {
    return this.restRequest.addAuthorization().put(`/annual-leave/${id}`, data);
  }

  annualLeave(data: any) {
    return this.restRequest
      .addAuthorization()
      .get(`annual-leaves?page=${data.page}&status=${data.status}`);
  }

  deleteAnnualLeave(id: any) {
    return this.restRequest.addAuthorization().delete(`annual-leave/${id}`);
  }

  getswms(data: any) {
    return this.restRequest.addAuthorization().get(`swmses?page=${data.page}`);
  }

  deleteswms(id: any) {
    return this.restRequest.addAuthorization().delete(`swms/${id}`);
  }
}
