import { Component, OnInit } from "@angular/core";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "../../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UserModalComponentComponent } from "./../user-modal-component/user-modal-component.component";
import { ExcelService } from "./../../../services/excel.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-inspection-list",
  templateUrl: "./inspection-list.component.html",
  styleUrls: ["./inspection-list.component.sass"],
})
export class InspectionListComponent implements OnInit {
  loader = true;
  items: any;
  items1: any;
  listData: any;
  status1: any;
  reason: any;
  email;
  email1;
  p = 1;
  nodata = false;
  searchText = "";
  selecttype;
  arr_temp = [];
  arr_temp_all = [];
  modalRef: BsModalRef;
  check: boolean;
  listtype_both: any;
  listtype_Accepted: any;
  listtype_Rejected: any;
  obj: any;
  excel = [];
  td;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;

  constructor(
    private inspection: InspectionService,
    private commonService: CommonService,
    //private router: Router,
    private modalService: BsModalService,
    private excelService: ExcelService
  ) {
    this.recordsPerPage = environment.defaultPageSize;
  }
  private searchTerms = new Subject<string>();
  ngOnInit() {
    this.getinspectionlist();
    this.status1 = [
      "Pending",
      "Qualified",
      "Accepted",
      "Rejected",
      "Confirmed",
    ];
    this.reason = ["Wrong service", "Wrong area"];
    this.arr_temp = [];
    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.query.search = value;
        this.pageChanged({ page: 1 });
        // this.getinspectionlistbystatus("open");
      });
    //    this.status = '';
  }

  getValue(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
    // return (event.target as HTMLInputElement).value;
  }

  getinspectionlistbystatus(obj) {
    this.query.search = this.searchText;
    this.inspection.getinspectionlistbystatusV2(obj, this.query).subscribe(
      (result: any) => {
        console.log("getinspectionlistbystatus >>>", result);

        const items = result.data.data;
        this.items = items;
        this.items1 = items;
        this.totalRecords = result.data.totalCount;
        this.loader = false;
        this.nodata = false;
        this.excel = [];
        let curVal = result.data;
        if (curVal.length > 0) {
          curVal.forEach((row) => {
            //delete objNew['_id'];
            console.log(row);
            this.excel.push(row);
          });
        }

        this.arr_temp_all = this.items.map((result) => result._id);
        //  console.log(this.arr_temp_all);
        if (obj == "Accepted") {
          this.listtype_both = false;
          this.listtype_Accepted = true;
          this.listtype_Rejected = false;
        } else if (obj == "Rejected") {
          this.listtype_both = false;
          this.listtype_Accepted = false;
          this.listtype_Rejected = true;
        }
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }
  getinspectionlist() {
    this.query.search = this.searchText;
    this.inspection.getinspectionlistbystatusV2("open", this.query).subscribe(
      (result: any) => {
        const items = result.data.data;
        this.items = items;
        this.items1 = items;
        this.totalRecords = result.data.totalCount;
        this.excel = [];
        //this.excel.push()
        let curVal = result.data;
        if (curVal.length > 0) {
          curVal.forEach((row) => {
            //delete row['_id'];
            //delete row['image'];
            console.log(row);
            this.excel.push(row);
          });
        }
        this.arr_temp_all = this.items.map((result) => result._id);
        // console.log(this.arr_temp_all);
        this.loader = false;
        this.listtype_both = true;
        this.listtype_Accepted = false;
        this.listtype_Rejected = false;
        this.nodata = false;
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getinspectionlist();
  }
  Deletefnc() {
    // this.obj = {
    //obj : this.arr_temp
    //}
    if (this.arr_temp.length == 0) {
      this.commonService.toast("Please select an enquiry to delete", "Error");
    } else {
      this.inspection.deleteinspectionlist(this.arr_temp).subscribe(
        (result: any) => {
          this.loader = true;
          this.pagecall();
          this.loader = false;
          this.commonService.toast("Deletion successful", "Success");
        },
        (error: any) => {
          this.loader = false;
          //this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
    }
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excel, "User");
  }

  pagecall() {
    if (this.listtype_both == true) {
      this.getinspectionlist();
    } else if (this.listtype_Accepted == true) {
      this.getinspectionlistbystatus("Accepted");
    } else if (this.listtype_Rejected == true) {
      this.getinspectionlistbystatus("Rejected");
    }
  }
  userModal(userid) {
    console.log(userid);
    const initialState = {
      userid: userid,
    };
    this.modalService.show(UserModalComponentComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.pagecall();
    });
  }
  sendeinspectionmail(emailid, date, time, _id) {
    let obj1 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendeinspectionmail(obj1).subscribe(
        (result: any) => {
          this.commonService.toast(
            `Inspection email sent successfully.`,
            "Success"
          );
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  sendconfirmationemail(emailid, date, time, _id) {
    let obj2 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendconfirmationemail(obj2).subscribe(
        (result: any) => {
          this.commonService.toast(
            `Confirmation email  sent successfully.`,
            "Success"
          );
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  changeStatus(_id, event) {
    const status = event.target.value;
    this.inspection.updateStatus({ _id, Status: status }).subscribe(
      (result: any) => {
        this.commonService.toast(`Status updated successfully.`, "Success");
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  changereason(_id, event) {
    const reason = event.target.value;

    if (this.status1 == "") {
      this.commonService.toast(
        "Please select the status before selecting the reason",
        "Error"
      );
      this.pagecall();
    } else if (this.status1 == "Accepted") {
      this.commonService.toast("The enquiry is in accepted state", "Error");
      this.pagecall();
    } else {
      this.inspection.updatereason({ _id, Reason: reason }).subscribe(
        (result: any) => {
          this.commonService.toast(`Reason updated successfully.`, "Success");
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  deleteselection(_id, check) {
    if (check.target.checked == true) {
      this.arr_temp.push(_id);
      //console.log(this.arr_temp);
    } else if (check.target.checked == false) {
      for (let j = 0; j < this.arr_temp.length; j++) {
        if (this.arr_temp[j] === _id) {
          this.arr_temp.splice(j, 1);
        }
      }
      // console.log(this.arr_temp);
    }
  }
  deleteall(event) {
    const checked = event.target.checked;
    this.items.forEach((item) => (item.selected = checked));
    if (checked == true) {
      this.arr_temp = this.arr_temp_all;
    } else if (checked == false) this.arr_temp = [];
    // console.log(this.arr_temp);
  }
  listchange(event) {
    const listval = event.target.value;
    if (listval == "Both") {
      this.getinspectionlist();
    } else if (listval == "Accepted") {
      this.getinspectionlistbystatus("Accepted");
    } else if (listval == "Rejected") {
      this.getinspectionlistbystatus("Rejected");
    }
  }
  selectinspectiondate(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate({ _id, idate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection date updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  selectinspectiondate1(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate1({ _id, cdate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Confirmed date updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  timeset(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time1")).value;
    this.inspection.selectinspectiontime1({ _id, itime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  timeset1(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time2")).value;
    this.inspection.selectinspectiontime2({ _id, ctime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  /* selectElementContents(el) {
     var body = document.body, range, sel;
     if (document.createRange && window.getSelection) {
         range = document.createRange();
         sel = window.getSelection();
         sel.removeAllRanges();
         try {
             range.selectNodeContents(el);
             sel.addRange(range);
         } catch (e) {
             range.selectNode(el);
             sel.addRange(range);
         }
         document.execCommand("copy");
 
     } else if (body.createTextRange) {
         range = body.createTextRange();
         range.moveToElementText(el);
         range.select();
         range.execCommand("Copy");
     }
 }*/
  // public getRoute(url, id) {
  //   const myurl = `${url}/${id}`;
  //   console.log(myurl);
  //   this.router.navigateByUrl(myurl).then(e => {
  //     if (e) {
  //       // console.log("Navigation is successful!");
  //     } else {
  //       console.log('Navigation has failed!');
  //     }
  //   });
  // }
  copydata(index) {
    var doc = [];
    var doc1 = "";
    var table = document.getElementById("myTable") as HTMLTableElement;
    //var row,header,cell1, cell2;
    //console.log(table.rows[index + 1], "tabletest")
    for (var i = 0; i < 9; i++) {
      //var date = table.rows[index+1].cells[i].innerText
      //table.rows[index + 1].cells[i].innerHTML;
      //console.log(table.rows[index + 1].cells[i].outerHTML)
      //doc.push(table.rows[index + 1].cells[i].innerText + "\t");
      doc1 = doc1 + table.rows[index + 1].cells[i].innerText + "\t";
      //doc.push(table.rows[index + 1].cells[i].innerText);
    }
    //console.log(doc);
    //console.log(doc1);
    //doc.splice(10, 6);
    //doc.splice(0, 1);
    //doc.join('\r\n');
    //doc.join("\n")
    //console.log(doc)
    //var str = doc.toString();
    //str.replace(",", "<br />");

    //this.copyStringToClipboard(table.rows[index+1].innerText)
    this.copyStringToClipboard(doc1);

    //If you want inner text
    //console.log(table.rows[index+1].innerText)

    ///If you want inner html
    //this.td = table.rows[index+1].innerHTML;

    // var parser = new DOMParser();

    //var doc1 = parser.parseFromString(this.td, 'text/html');
    //doc1.body.select();

    //var dummy = document.createElement("input");
    //dummy = this.td;
    //dummy.select();
    //document.execCommand("copy");

    //td.select();
    // doc.execCommand("copy");
    //doc.select();
    //var row = table.rows[index+1].cells;
    //console.log(row)
    //var table = <HTMLTableElement> document.getElementById("myTable").rows[0].innerHTML
    // var table = document.getElementById("myTable");
    //console.log(table.outerText )
    //  var  tr = table.getElementsByTagName("tr");
    //var td = tr[index].getElementsByTagName("td");
    //console.log(td);
    //let td: HTMLInputElement = tr[index].getElementsByTagName("td") as HTMLInputElement;

    //td.select();
    //td.setSelectionRange(0, 99999);

    /* Copy the text inside the text field */
    //document.execCommand("copy");
    /* Alert the copied text */
    //alert("Copied the text: " + td.value);
  }

  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    //el.style = {position: 'absolute', left: '-9999px'};

    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }
}
