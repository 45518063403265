import { Component, OnInit, TemplateRef } from "@angular/core";
import { UserListService } from "../services/user-list.service";
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.sass"],
})
export class UserListComponent implements OnInit {
    items;
    loader;
    nodata = false;
    recordsPerPage: number;
    isAction = true;
    p = 1;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    totalRecords = 0;
    modalRef?: BsModalRef;
    selectedItem: any;

    constructor(
        private userListService: UserListService,
        private spinner: NgxSpinnerService,
        private commonService: CommonService,
        private router: Router,
        private modalService: BsModalService
    ) {
        this.recordsPerPage = environment.defaultPageSize;
    }

    private searchTerms = new Subject<string>();
    ngOnInit() {
        this.loader = true;
        this.searchTerms
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.pageChanged({ page: 1 });
                this.query.search = value;
                this.getUserList();
            });

        this.getUserList();
    }

    getValue(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.searchTerms.next(inputElement.value);
        // return (event.target as HTMLInputElement).value;
    }

    getUserList() {
        // this.query.search = this.searchText; // Add the search query parameter
        this.userListService.getUserList(this.query).subscribe(
            (result: any) => {
                this.nodata = false;
                // const items = result.data.data.filter(res=>{return res.active});
                this.items = result.data.data.filter((res) => {
                    return res.active;
                });
                this.totalRecords = result.data.totalCount;
                this.loader = false;
            },
            (error: any) => {
                this.nodata = true;
                this.loader = false;
            }
        );
    }

    navigateAddUser() {
        this.router.navigate(["/users/add"]);
    }

    public getRoute(url, id) {
        const myurl = `${url}/${id}`;
        this.router.navigateByUrl(myurl).then((e) => {
            if (e) {
                // console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }

    sendAccess(emailId) {
        this.loader = true;
        const obj = { email: emailId };
        this.userListService.sendAccessKey(obj).subscribe(
            (result: any) => {
                if (result.success === true) {
                    this.loader = false;
                    this.commonService.toast(result.message, "Success");
                }
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    changeStatus(id, event) {
        const status = event.target.checked ? "1" : "0";
        this.userListService.updateUserStatus(id, { status }).subscribe(
            (result) => {
                this.getUserList();
                this.commonService.toast(
                    `Status updated successfully.`,
                    "Success"
                );
            },
            (error) => {}
        );
        console.log(id, event);
    }

    changeEngineer(id, event) {
        const isengineer = event.target.checked ? "1" : "0";
        this.userListService.updateUserEngineer(id, { isengineer }).subscribe(
            (result) => {
                this.getUserList();
                this.commonService.toast(
                    `Status updated successfully.`,
                    "Success"
                );
            },
            (error) => {}
        );
        console.log(id, event);
    }

    pageChanged(event: any): void {
        this.p = event.page;
        this.query.page = this.p;
        this.getUserList();
    }
    deleteMethod(user) {
        this.userListService.inActiveUser(user._id).subscribe(
            (res) => {
                console.log(res);
                this.loader = true;
                this.getUserList();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    openModal(template: TemplateRef<any>, item) {
        this.selectedItem = item;
        this.modalRef = this.modalService.show(template, { class: "modal-sm" });
    }

    confirm() {
        this.deleteMethod(this.selectedItem);
        // this.message = 'Confirmed!';
        this.modalRef.hide();
    }

    decline() {
        // this.message = 'Declined!';
        this.modalRef.hide();
    }
}
