import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { TimeSheetService } from "../timesheet.service";
import { SupportTicketService } from "../../support-ticket/support-ticket.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-timesheet-form",
  templateUrl: "./timesheet-form.component.html",
  styleUrls: ["./timesheet-form.component.sass"],
})
export class TimesheetFormComponent implements OnInit {
  isValid = false;
  submitted = false;
  formData: FormGroup;
  disableSubmit = false;
  showMessage = false;
  fileToUpload: File = null;
  Dayentries = [
    {
      day: "Monday",
      hours: "",
      description: "",
    },
    {
      day: "Tuesday",
      hours: "",
      description: "",
    },
    {
      day: "Wednesday",
      hours: "",
      description: "",
    },
    {
      day: "Thursday",
      hours: "",
      description: "",
    },
    {
      day: "Friday",
      hours: "",
      description: "",
    },
  ];

  isLoading = false;
  images = [""];
  users = [];
  hours = [
    {
      label: "0 Hour",
      value: "0",
    },
    {
      label: "1 Hour",
      value: "1",
    },
    {
      label: "2 Hour",
      value: "2",
    },
    {
      label: "3 Hour",
      value: "3",
    },
    {
      label: "4 Hour",
      value: "4",
    },
    {
      label: "5 Hour",
      value: "5",
    },
    {
      label: "6 Hour",
      value: "6",
    },
    {
      label: "7 Hour",
      value: "7",
    },
    {
      label: "8 Hour",
      value: "8",
    },
    {
      label: "AL",
      value: "annual_leave",
    },
    {
      label: "SL",
      value: "sick_leave",
    },
    {
      label: "UL",
      value: "unpaid_leave",
    },
  ];
  overtime = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  travelTime = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  overNightAllowance = [1, 2, 3, 4, 5, 6, 7];
  incompleteJobs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  customerExperienceBonus = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  jobCompleted = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  rateYourWeek = [
    {
      label: "3. Great",
      value: "3",
    },
    {
      label: "2. Ok",
      value: "2",
    },
    {
      label: "1. Poor",
      value: "1",
    },
  ];

  selectedUser = {};
  optionsVisibility = {};

  private initialData = {
    employeeId: "",
    entries: [
      {
        day: "monday",
        hours: "",
        description: "",
      },
      {
        day: "tuesday",
        hours: "",
        description: "",
      },
      {
        day: "wednesday",
        hours: "",
        description: "",
      },
      {
        day: "thursday",
        hours: "",
        description: "",
      },
      {
        day: "friday",
        hours: "",
        description: "",
      },
    ],
    rateWeek: {
      value: "",
      description: "",
    },
    travelTime: {
      value: "",
      description: "",
    },
    overtime: {
      value: "",
      description: "",
    },
    incompleteJobs: {
      value: "",
      description: "",
    },
    overNightAllowance: "",
    customerExperienceBonus: {
      value: "",
      description: "",
    },
    jobCompleted: "",
  };

  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    private api: TimeSheetService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formData = this._fb.group({
      employeeId: [this.initialData.employeeId, [Validators.required]],
      overtime: this._fb.group({
        value: this.initialData.overtime.value,
        description: this.initialData.overtime.description,
      }),
      incompleteJobs: this._fb.group({
        value: this.initialData.incompleteJobs.value,
        description: this.initialData.incompleteJobs.description,
      }),
      travelTime: this._fb.group({
        value: this.initialData.travelTime.value,
        description: this.initialData.travelTime.description,
      }),
      overNightAllowance: [this.initialData.overNightAllowance],
      customerExperienceBonus: this._fb.group({
        value: this.initialData.customerExperienceBonus.value,
        description: this.initialData.customerExperienceBonus.description,
      }),
      rateWeek: this._fb.group({
        value: [this.initialData.rateWeek.value, [Validators.required]],
        description: [this.initialData.rateWeek.description],
      }),
      jobCompleted: [this.initialData.jobCompleted],
      entries: this._fb.array(
        this.initialData.entries.map((entry) => this.createEntryGroup(entry))
      ),
    });

    this.handleUserSearch("");
  }

  createEntryGroup(entry: any): FormGroup {
    return this._fb.group({
      day: entry.day,
      hours: [entry.hours, [Validators.required]],
      description: [entry.description],
    });
  }

  get f() {
    return this.formData.controls;
  }
  get f1() {
    return this.formData.get("entries") as FormArray;
  }

  get rateweek() {
    return this.formData.get("rateWeek").get("value");
  }

  handleSelectSearch(i: any, controlName: any, selectedValue: any) {
    const entriesFormArray = this.formData.get("entries") as FormArray;
    const entryFormGroup = entriesFormArray.at(i) as FormGroup;
    entryFormGroup.controls["hours"].setValue(selectedValue);
  }

  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;
    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  handleUserSelect(item: any) {
    this.selectedUser = item;
    this.formData.patchValue({
      employeeId: item._id,
    });
  }

  handleUserSearch(event: any) {
    const str = event ? event.target.value : "";
    const data = {
      search: str,
    };

    this.api.getUsers(data).subscribe(
      (result: any) => {
        const response = result.data;

        if (response.items) {
          this.users = response.items;
        }
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );

    this.selectedUser = {};
    this.formData.patchValue({
      employeeId: "",
    });
  }

  handleSelectHour(event: any) {}

  addImages() {
    if (this.isLoading) {
      return this.commonService.toast("uploading Doc", "Info");
    }
    this.images.push("");
  }

  removeimage(index: number) {
    if (this.images.length > 1) {
      this.images.splice(index, 1);
    } else {
      this.images[0] = "";
    }
  }

  handleFileInput(files: FileList, type = "image", index: number) {
    this.fileToUpload = files.item(0);

    if (files.length === 1) {
      this.isLoading = true;

      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.isLoading = false;

          if (type === "image") {
            this.images.pop();
            this.images.push(result.data);
          } else {
            // this.video = result.data;
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  handleSubmit() {
    this.submitted = true;

    if (this.formData.valid && this.isLoading === false) {
      console.log("hit in api");
      const formData = this.formData.value;
      console.log(formData, "the formData");
      this.isLoading = true;

      this.api
        .addData({
          ...formData,
          images: this.images.filter((data) => data !== ""),
        })
        .subscribe(
          (result: any) => {
            // this.formData.patchValue(this.initialData);
            // this.selectedUser = {};
            this.images = [""];
            // this.video = "";
            this.isLoading = false;
            this.showMessage = true;

            this.router.navigate(["/Timesheet/list/weekly_time_sheet"]);
            this.commonService.toast(result.message, "Success");
            this.submitted = false;
          },
          (error: any) => {
            this.isLoading = false;
            this.commonService.toast(error.error.message, "Error");
          }
        );
    }
  }
}
